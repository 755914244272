import React from 'react';
import './style/App.css';

const App = () => {
  return (
    <div className="container">
      <h1>Logs</h1>
      <p>
        Recent advancements in applied cryptography and distributed systems have empowered a new
        medium of exchange and payment rail: <b>self-custodial money on Ethereum L2s</b>. It is the
        only rail that has reliably achieved three properties:
      </p>
      <ul>
        <li><b>Borderless:</b> anyone with internet access can transact with each other</li>
        <li><b>Fast:</b> settle in seconds</li>
        <li><b>Cheap:</b> sub-cent fee per transaction</li>
      </ul>
      <p>
        A tremendous amount of GDP is locked up because people can’t transact conveniently at low cost.
        The transition from today’s high-cost, time-intensive, walled-garden financial systems will
        be gradual, requiring both state-of-the-art products and an efficient go-to-market push. <b>At
        Logs, we deliver a product suite to drive this shift towards frictionless money movement, starting with reducing international c2b payment cost by 95%.</b>
      </p>  
      <p><b><i><a href="https://form.jotform.com/250026183216043">Inquiry</a></i> </b></p> 
      <p><b><a href="https://merchant.logs.money/login">Accept Payment Now</a> </b></p> 
      <h3><i>The beginning is a peculiar time.</i></h3>
    </div>
  );
};

export default App;
